.careers-container {
  display: flex;
  justify-content: center;
  padding: 2rem;
  min-height: 100vh;
}

.careers-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0px 20px rgb(66, 121, 129);
  max-width: 800px;
  width: 100%;
}

.careers-content h1,
.careers-content h2 {
  text-align: center;
  color: #333;
}

.careers-content p {
  text-align: center;
  color: #666;
}

.careers-content hr {
  margin: 1.5rem 0;
}

.careers-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-row {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.input-container {
  flex: 1;
}

.input-container label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.input-container input,
.input-container .react-select__control {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}
.input-container textarea {
  width: 96.3%;
  padding: 0.75rem;
  resize: none;
  height: 19rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.input-container .phone-input-container .form-control {
  width: 100%;
  padding: 0.2 2.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s ease;
}

.input-container input:focus,
.input-container textarea:focus,
.input-container .react-select__control--is-focused,
.input-container .react-phone-input input:focus {
  border-color: #007bff;
}

.cover-letter {
  flex: 1;
  display: flex;
  flex-direction: column;
}

/* .careers-form button {
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.careers-form button:hover {
  background-color: #0056b3;
} */

.careers-container p {
  text-align: justify;
}

.resume-uploader {
  margin: 0;
  height: 2.5rem;
  padding: 0;
}

.keep-in-touch{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

