*{
  margin: 0;
  padding: 0;
}

.route-container{
  display: flex;
  /* margin-top: 25vh; */
  justify-content: center;
  /* align-items: center; */
}

/* .parent-container{
  margin: 0;
} */