.parent-navbar {
  height: 7.3vh;
  background-color: black;
  flex-direction: row;
  color: white;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: end;
  align-items: end;
}
.upper-nav{
  display: flex;
  height: 4rem;
}
.navbar-container {
  display: flex;
  flex-direction: row;
}
.navbar-container li {
  margin-right: 2rem;
}

/* .navbar-container .contact-nav:hover{
    color: rgb(46, 194, 172);
    transition: 0.3s;
    cursor: pointer;
} */

.contact-link {
  list-style: none;
  color: white;
}
.contact-nav {
  color: white;
  text-decoration: none;
  font-size: larger;
  text-decoration: none;
  padding: 10px 0;
  position: relative;
  transition: color 0.3s ease;
}

.active {
  color: rgb(241, 33, 33);
  text-decoration: none;
  font-size: larger;
  text-decoration: none;
}
.contact-nav::after {
  content: "";
  position: absolute;
  width: 0;
  height: 4px;
  bottom: 1px;
  left: 50%;
  background-color: #ffd103;
  transition: width 0.3s ease, left 0.3s ease;
}

.contact-nav:hover::after {
  width: 100%;
  left: 0;
}

.active {
  color: #007bff;
}

.active::after {
  width: 100%;
  left: 0;
}
.contact-nav.active::after {
  width: 100%;
  left: 0;
}
.dropdown-nav {
  color: white;
  text-decoration: none;
  font-size: larger;
  text-decoration: none;
  padding: 10px 0;
  position: relative;
  transition: color 0.3s ease;
}
.dropdown-nav:hover{
    cursor: pointer;
}


.dropdown-nav:hover::after {
  width: 100%;
  left: 0;
}

.dropdown-nav.active::after {
  width: 100%;
  left: 0;
}

.dropdown {
  position: relative;
  z-index: 10;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: black;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 220px;
}

.dropdown-menu li {
  list-style: none;
  padding: 0.5rem 1rem;
}

.dropdown-menu li a {
  color: white;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s ease;
}

.dropdown-menu li a:hover {
  left: 50%;
  color: #ffd103;
  transition: width 0.3s ease, left 0.3s ease;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.hamburger {
  display: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .parent-navbar {
    flex-direction: column;
    align-items: center;
    height: auto;
    display: none; /* Hide the menu by default */
  }

  .parent-navbar.open {
    display: flex; /* Show the menu when open */
  }

  .navbar-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .navbar-container li {
    margin-right: 0;
    margin-bottom: 0.3rem;
  }

  .contact-nav {
    font-size: 0.7rem;
  }

  .upper-nav-container {
    flex-direction: column;
    align-items: center;
  }

  .dropdown-menu {
    position: static;
    box-shadow: none;
    min-width: 100%;
  }
  .dropdown-nav{
    font-size: 0.7rem;
  }

  .dropdown-menu li {
    padding: 0.5rem;
    font-size: 0.7rem;
    text-align: center;
  }

  .dropdown-menu li a {
    padding: 0.5rem 1rem;
  }

  .upper-nav {
    height: auto;
    padding: 1rem 0;
  }

  .upper-nav-link {
    font-size: 2rem;
  }

  .upper-nav-link img {
    height: 2.5rem;
    margin-right: 0.5rem;
  }
  .hamburger {
    display: block;
    background-color: black;
  }
}