/* src/style/Footer.css */
.footer {
    background-color: #343a40;
    color: #ffffff;
    padding-left: 7rem;
    padding-right: 7rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  
  .footer-col h5 {
    font-size: 18px;
    margin-bottom: 20px;
    color: #ffffff;
  }
  
  .footer-col p,
  .footer-col ul {
    font-size: 14px;
    line-height: 1.6;
    color: #d4d4d4;
  }
  
  .footer-col ul {
    padding: 0;
    list-style: none;
  }
  
  .footer-col ul li {
    margin-bottom: 10px;
  }
  
  .footer-col ul li a {
    color: #d4d4d4;
    text-decoration: none;
  }
  
  .footer-col ul li a:hover {
    color: #007bff;
  }
  
  .social-icons {
    display: flex;
    justify-content: flex-start;
    padding: 0;
    list-style: none;
  }
  
  .social-icons li {
    margin-right: 10px;
  }
  
  .social-icons li a {
    color: #d4d4d4;
    font-size: 20px;
  }
  
  .social-icons li a:hover {
    color: #007bff;
  }
  