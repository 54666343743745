/* src/style/Home.css */
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&family=Poppins:wght@300;400;600&display=swap');

:root {
  --primary-color: #0d6efd;
  --secondary-color: #6c757d;
  --accent-color: #ff6347;
  --background-color: #f8f9fa;
  --text-color: #343a40;
  --light-text-color: #6c757d;
  --border-radius: 10px;
  --box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.home-container {
  /* padding: 20px; */
  /* max-width: 1200px; */
  margin: 0 0;
  overflow: hidden;
}
.content-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.carousel-container {
  position: relative;
  height: 91.79vh;
  width: 100vw;
  background-color: #333;
  /* border-radius: var(--border-radius); */
  overflow: hidden;
  margin-bottom: 40px;
}

.carousel-image {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  animation: slideIn 4s infinite;
}

.carousel-content.active {
  opacity: 1;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.carousel-text {
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: var(--border-radius);
  animation: slideInUp 4s infinite;
  transition: 0.5s;
}

@keyframes slideInUp {

  0%,
  100% {
    transform: translateY(80px);
  }

  50% {
    transform: translateY(10px);
  }
}

.home-header {
  text-align: center;
  margin-bottom: 40px;
}

.home-logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
  animation: logoBounce 3s infinite;
}

@keyframes logoBounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

h1 {
  font-family: 'Nunito', sans-serif;
  font-size: 48px;
  color: var(--primary-color);
  animation: fadeInDown 1s forwards;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: var(--accent-color);
  text-align: center;
  margin: 40px 0;
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(1px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

h3 {
  font-weight: 600;
  color: var(--primary-color);
  margin-top: 20px;
}

p,
ul li {
  font-size: 1rem;
  line-height: 1.8;
  color: var(--light-text-color);
}

ul {
  list-style: none;
  padding: 0;
}

ul li {
  margin-bottom: 15px;
  animation: fadeInUp 1s forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.creative-designer,
.parameters,
.contact-info {
  background-color: #fff;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 40px;
  animation: fadeIn 1s forwards;
}

.contact-info p {
  margin: 10px 0;
}

.work-image {
  width: 100%;
  border-radius: var(--border-radius);
  margin-top: 20px;
  box-shadow: var(--box-shadow);
  transition: transform 0.3s ease-in-out;
}

.work-image:hover {
  transform: scale(1.05);
}

.home-footer {
  background-color: #fff;
  color: #fff;
  padding: 30px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  text-align: center;
  margin-top: 40px;
}

.home-footer .useful-links ul {
  padding: 0;
  margin: 20px 0;
}

.home-footer .useful-links ul li {
  margin: 10px 0;
}

.home-footer .company-info p {
  margin: 10px 0;
}

@media (max-width: 768px) {
  h1 {
    font-size: 24px;
  }
  h2{
    font-size: 20px;
  }

  p,
  ul li {
    font-size: 16px;
  }

  .creative-designer,
  .parameters,
  .contact-info {
    padding: 20px;
  }

  .home-footer {
    padding: 20px;
  }
}


h1:hover {
  font-size: xx-large;
}


.exhibition-stall-h2:hover {
  font-size: 1.64rem;
}

.creative-designer:hover,
.parameters:hover,
.services:hover,
.contact-info:hover,
.home-footer:hover {
  background-color: rgb(255, 238, 111);
  transition: 0.3s;
  box-shadow: 10px 10px 20px #babecc,-10px -10px 20px #09414185;
}

.page-links {
  color: #343a40;
  text-decoration: none;
  display: block;
  font-size: larger;
  margin-bottom: 0.6rem;
}

.page-links:hover {
  color: #ffffff;
}

@keyframes fadeInService {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUpService {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.services {
  background-color: #fff;
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  margin-bottom: 40px;
}

.service-img-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.service-card {
  position: relative;
  overflow: hidden;
  width: 220px;
  height: 300px;
  border-radius: 0.7rem;
}
.service-card:hover .service-image {
  filter: blur(0px);
  transform: scale(1.1);
  box-shadow:  0 0 20px rgba(0, 0, 0, 0.9 );
}

.service-image {
  width: 100%;
  
  filter: blur(19px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s, transform 0.3s;
}

.service-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  opacity: 1;
  transition: opacity 0.3s;
  pointer-events: none;
}
.service-card:hover .service-name {
  opacity: 0;
}

@media screen and (max-width: 1000px) {
  .service-name{
    height: 5rem;
  }
  .exhibition-stall-h2:hover {
    font-size: 1.2rem;
  }
}

.center-service {
  transform: scale(1.1);
  /* Increase size by 10% */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.clients{
  width: 100%;
  padding-top: 0.5rem;
  background-color: #343a40;
}

.honeycomb-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.honeycomb-cell {
  width: 8rem;
  padding-bottom: 10%;
  position: relative;
  margin: 5px;
}

.honeycomb-cell img {
  position: absolute;
  width: 100%;
  padding: 0.2rem;
  height: 100%;
  object-fit: contain;
}

.honeycomb-cell img:hover{
  scale: 1.2;
  transition: 0.2s;
}