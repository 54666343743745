/* src/style/AboutUs.css */
.plain-text{
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: rgb(0, 0, 0);
    font-size: medium;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&family=Roboto:wght@300;400&display=swap');

.about-us-container {
  padding: 3rem 15rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1.6;
  background-color: #ffffff;
  color: #333;
  opacity: 0;
  animation: fadeIn 1s forwards;
  overflow: hidden;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}
h1{
    height: 6rem;
}
h2{
    height: 4rem;
}

h1, h2 {
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-bottom: 20px;
  color: #007bff;
  position: relative;
  opacity: 0;
  animation: slideIn 1s forwards;
}

@keyframes slideIn {
  from {
    transform: translateY(-20px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

h1::after, h2::after {
  content: '';
  display: block;
  width: 100px;
  height: 2px;
  background: #ff6347;
  margin: 10px auto;
}

h3 {
  margin-top: 20px;
  color: #ff6347;
  font-family: 'Poppins', sans-serif;
  opacity: 0;
  animation: fadeIn 1.5s forwards;
}

h1:hover{
    font-size: xx-large;
    text-shadow: 4px 4px 5px rgb(83, 163, 237);
    transition: 0.3s;
}
h1:not(:hover){
    transition: 0.3s;
    font-size: x-large;
}

h2:hover{
    font-size: xx-large;
    text-shadow: 4px 4px 5px rgb(255, 196, 47);
    transition: 0.3s;
}
h2:not(:hover){
    transition: 0.3s;
    font-size: x-large;
}

.know-us-better, .what-we-do, .why-choose-us, .who-we-are, .our-clients {
  margin-bottom: 40px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  opacity: 0;
  animation: fadeInUp 1s forwards;
}
.know-us-better:hover, .what-we-do:hover, .why-choose-us:hover, .who-we-are:hover, .our-clients:hover{
    background-color: rgb(255, 238, 111);
    transition: 0.4s;
    box-shadow: 10px 10px 20px #babecc,-10px -10px 20px #09414185;
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.mission-vision {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mission, .vision {
  flex: 1 1 45%;
  margin: 10px 0;
}

.mission ul, .vision ul, .principals ul, .team ul, .strength ul {
  list-style: none;
  padding-left: 0;
}

.mission ul li, .vision ul li, .principals ul li, .team ul li, .strength ul li {
  margin-bottom: 10px;
  opacity: 0;
  animation: fadeInRight 1.5s forwards;
}

@keyframes fadeInRight {
  from {
    transform: translateX(-20px);
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.gallery {
  text-align: center;
  opacity: 0;
  animation: fadeIn 2s forwards;
}

.gallery .images {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 0.6rem;
}

.gallery-image {
  width: 300px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.15);
  box-shadow: 0 0 80px rgba(47, 175, 238, 0.472);

}

/* Responsive Styles */
@media (max-width: 1024px) {
  .about-us-container {
    padding: 2rem 10rem;
  }
  .about-us-container h2:hover{
    font-size: 1.9rem;
  }
  h2:not(:hover){
    font-size: 1rem;
  }
  h2:hover{
    font-size: 1.5rem;
  }

  .gallery .images {
    gap: 5px;
  }

  .gallery-image {
    width: 250px;
    height: 150px;
  }
}

@media (max-width: 768px) {
  .about-us-container {
    padding: 2rem 5rem;
  }
  h2:not(:hover){
    height: 6rem;
  }
  h2:hover{
    height: 6rem;
  }

  .about-us-  h1 {
    font-size: 2rem;
  }

  .about-us-h2 {
    font-size: 1.5rem;
  }

  h1 {
    font-size: 1.5rem;
  }

  .know-us-better, .what-we-do, .why-choose-us, .who-we-are, .our-clients {
    padding: 15px;
  }

  .mission, .vision {
    flex: 1 1 100%;
    margin: 10px 0;
  }

  .gallery-image {
    width: 200px;
    height: 120px;
  }
}

@media (max-width: 576px) {
  .about-us-container {
    padding: 1rem 2rem;
  }

  h1 {
    font-size: 1.25rem;
  }

  .know-us-better, .what-we-do, .why-choose-us, .who-we-are, .our-clients {
    padding: 10px;
  }

  .gallery .images {
    flex-direction: column;
    align-items: center;
  }

  .gallery-image {
    width: 100%;
    height: auto;
  }
}

